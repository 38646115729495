import React from 'react'
import { Layout } from 'antd';
import './header.less';
const { Header } = Layout;

export default function HeaderComponent() {
  return (
    <Header className='headerContainer'>
      <span>哆啦好租</span>
    </Header>
  )
}
