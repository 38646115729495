import React from 'react'
import './footer.less'
import { Space, Divider, Layout } from 'antd';


const { Footer } = Layout;

export default function FooterComponent() {
  return (
    <Footer className='footerClass'>
      <Space split={<Divider type="vertical" />}>
        <div>关于我们</div>
        <div>服务类型</div>
        <a href="https://beian.miit.gov.cn">浙ICP备2023018888号-1</a>
      </Space>
    </Footer>
  )
}
