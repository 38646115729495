import React from 'react'

import { Layout } from 'antd';

import Footer from '@/components/footer/Footer.js';
import Header from '@/components/header/Header.js';
import i1 from '@/images/i_1.jpeg'

const { Content } = Layout;


export default function Home() {
  return (
    <Layout>
      <Header></Header>
      <Content>
        <img src={i1} alt="" style={{width: '100%', height: '100%'}} />
      </Content>
      <Footer></Footer>
    </Layout>
  )
}
