import Home from './pages/home/Home';
import './App.css';

function App() {
  return (
    <div className='app'>
      <Home></Home>
    </div>
    
  );
}

export default App;
